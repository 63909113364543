//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import GoogleReCaptcha from '~/components/ReusableComponents/GoogleReCaptcha.vue';
import AuthContainer from '~/components/Auth/AuthContainer.vue';
import analyticEvents, { isValidSignupType } from '~/services/analyticEvents';

import { archiveBook, documentCopy, userHexagon, presentationChart } from '~/static/icons/index';
import { authFooterPattern } from '~/static/images/index';
import { ROUTES } from '~/constants/pageRoutes';
import { getFullUrl } from '~/services/utilityFunctions';

const ReportLoader = () => import('~/components/Report/CommonComponents/ReportLoader.vue');

export default {
    name: 'Login',
    components: {
        GoogleReCaptcha,
        AuthContainer,
        ReportLoader
    },
    layout: 'nonNav',
    data() {
        return {
            // ICONS
            archiveBook,
            documentCopy,
            userHexagon,
            presentationChart,
            authFooterPattern,
            yocketPrepLogo: `${process.env.CDN_ENDPOINT}/testprep/static/yocketPrep.png`,

            // Variables
            isDiagnosticTestAuth: false
        };
    },
    head() {
        return {
            link: [{ rel: 'canonical', href: getFullUrl(ROUTES.LOGIN) }]
        };
    },
    computed: {
        ...mapGetters({
            showReportLoader: 'dashboard/showReportLoader'
        }),
        signupSource() {
            return this.$route.query?.src;
        },
        webStepZeroSignupHeading() {
            return this.isDiagnosticTestAuth
                ? 'Sign up to access your test report!'
                : 'Start your Test Prep Journey Today!';
        },
        webStepZeroSignupSubheading() {
            if (this.$device.isMobile)
                return 'Get 3 free days with Yocket Prep to explore all our features.';

            return this.isDiagnosticTestAuth
                ? 'Get insights into your strengths and weakness to prep better.'
                : 'Get 3 free days with Yocket Prep to explore all our features.';
        },
        webStepOneHeading() {
            if (this.signupSource && this.signupSource === 'free-starter-kit') {
                return 'Just one step away from accessing your free resources';
            }

            return 'Hello there!\n Let’s get introduced';
        },
        signupType() {
            const signupType = this.$route.query.source;
            // default signupType is `navbar_signup`
            return isValidSignupType(signupType) ? signupType : analyticEvents.signup_type.navbar;
        }
    },
    created() {
        if (this.$route.query?.source === 'quick_diag_report_signup') {
            this.isDiagnosticTestAuth = true;
        }
    }
};
