//
//
//
//
//
//
//
//
//

import lottie from 'lottie-web';
import reportLoader from '~/static/animations/reportLoader.json';
export default {
    name: 'ReportLoader',
    data() {
        return {
            spanText: 'Analysing'
        };
    },
    computed: {},
    mounted() {
        lottie.loadAnimation({
            container: this.$refs.loader,
            animationData: reportLoader
        });
        setInterval(() => {
            if (this.spanText === 'Analysing') this.spanText = 'Evaluating';
            else if (this.spanText === 'Evaluating') this.spanText = 'Generating';
        }, 2000);
        setTimeout(() => {
            this.$store.commit('dashboard/setShowReportLoader', false);
        }, 6000);
    }
};
